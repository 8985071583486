import React, {useContext, useEffect, useState} from "react";
import { ReactComponent as Merci } from "../../assets/svg/merci.svg";
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import { getClientByClient } from '../api';
import { useNavigate } from "react-router-dom";

export default function MerciPopup({setShowPopupMerci}) {

    const [onData, setOnData] = useState("");
    const { brand } = useContext(RedirectUrlValue);
    const navigate = useNavigate();
    const [error, setError] = useState("")
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                setOnData(clientData);
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, [brand]);

    useEffect(() => {
        if (error) {
            navigate("/");
        }
    }, [error, navigate]);

    return(
        <div onClick={() => setShowPopupMerci(false)} className="popup popupMerci">
            <Merci className="merci" fill={onData.couleur}/>
            <p>Votre collaboration nous est précieuse</p>
        </div>
    )
}