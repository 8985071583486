import axios from 'axios';

const headers = {
    'Content-Type': 'application/json',
    'apiKey': process.env.REACT_APP_API_SECRET_HASH
};

export const getClientByClient = async (clientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo/client/${clientId}`, { headers });
        return response.data[0];
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getClient = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getOnLanguage = async (lang) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/langue/`, { headers });
        return response.data[0][lang];
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getModule = async (clientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/module/client/${clientId}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getModuleById = async (Id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/module/${Id}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const languageToModuleMap = {
    "Français": "greenofficefr",
    "anglais": "greenofficeen",
    "deutsch": "greenofficede"
};

export const getCompleteClientInfo = async (brand, language) => {
    try {
        const clientData = await getClientByClient(brand);
        let languageData;
        const languageList = clientData.langue.split(' ');
        if (clientData.version === "demo") {
            languageData = await getOnLanguage(language);
        } else if (languageList.length > 1){
            languageData = await getOnLanguage(language);
        } else {
            languageData = await getOnLanguage(clientData.langue);
        }

        let moduleData;
        if (clientData.version === "standard") {
            const moduleKey = languageToModuleMap[clientData.langue] || "greenofficeen";
            moduleData = await getModule(moduleKey);
        } else {
            moduleData = await getModule(clientData.client);
        }

        return {
            clientData,
            languageData,
            moduleData,
        };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};