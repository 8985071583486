import React, { useState, useEffect, useContext, useCallback  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageValue } from '../../context/LanguageValue';
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import PopupMerci from "../popup/Merci.js";
import ValidationDemandeDeService from "../popup/ValidationDemandeDeService.js";
import "../../styles/DemandeDeService.css";
import { getClientByClient, getModuleById, getOnLanguage } from '../api';
import Loading from '../Loading';

export default function DemandeDeService() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedAction, setSelectedAction] = useState([]);
    const [showActiveButton, setShowActiveButton] = useState(true);
    const [showPopupMerci, setShowPopupMerci] = useState(false);
    const [showPopupValidationDemandeDeService, setShowPopupValidationDemandeDeService] = useState(false);
    const [selectEtage, setSelectEtage] = useState([]);
    const [selectLocalisation, setSelectLocalisation] = useState("");
    const { language } = useContext(LanguageValue);
    const [onData, setOnData] = useState(null);
    const [module, setModule] = useState(null);
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);
    const [onLanguage, setOnLanguage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                setOnData(clientData);
                const moduleData = await getModuleById(state.module);
                setModule(moduleData);
                const languageData = await getOnLanguage(language);
                setOnLanguage(languageData);
            } catch (error) {
                setError('Une erreur s\'est produite lors de la récupération des données.');
            }
            window.scrollTo(0, 1000);
        };
        fetchData();
    }, [brand, language, state.module])

    useEffect(() => {
        if(error) {
            console.log(error)
            navigate("/");
        }
    }, [error, navigate])
    
/* Affichage des différents picto */

    useEffect(() => {
        if (module !== null) {
            setSelectEtage(("1 _2 _3 _4").split('_'))

            if (module.localisation !== undefined) {
                if(module.localisation.includes(',')) {
                    setSelectLocalisation(module.localisation.split(','))
                } else {
                    setSelectLocalisation(module.localisation);
                }
            }
            if (module.etage !== undefined) {
                setSelectEtage((module.etage));
            }
        }
    }, [module])

/* slélecitonné les actions */
    function handleSelectAction(actionId, actionText) {
        if (selectedAction.includes(actionText)) {
            setSelectedAction(selectedAction.filter(e => e !== actionText));
            const selectedElementText = document.getElementsByClassName(actionId)[0];
            selectedElementText.style.color = "black";

            const selectedElementModule = document.getElementsByClassName("module-" + actionId + "img")[0];
            selectedElementModule.style.background = "var(--grey)";
        } else {
            setSelectedAction([...selectedAction, actionText])

            const selectedElementText = document.getElementsByClassName(actionId)[0];
            selectedElementText.style.color = onData.couleur;

            const selectedElementModule = document.getElementsByClassName("module-" + actionId + "img")[0];
            selectedElementModule.style.background = onData.couleur;
        }
    }

/* activé le boutton */
    
    const handleActiveButton = useCallback(() => {
        var inputValue = document.getElementById("locationInput")?.value || "";
        var selectValue = document.getElementById("etage-select")?.value || "Étage";
        setShowActiveButton(!(selectedAction.length && inputValue.length && selectValue !== 'Étage'));
    }, [selectedAction]);

    useEffect(() => {
        handleActiveButton()
    }, [selectedAction, handleActiveButton]);

    function handleValidComment() {
        setShowPopupValidationDemandeDeService(true);
    }

    function handleNoPopup() {
        setShowPopupValidationDemandeDeService(false)
    }
    
    useEffect(() => {
        if(showPopupMerci === true) {
            setTimeout(() => {
                document.location.reload()
            }, 2000)
        }
    }, [showPopupMerci])

    return(
        <>
            {module && module.details ? (

                <div className="demandeDeServicePage page">
                    <div className='titlePage'>
                        <img src={module.imageUrl} alt="" />
                        <div className="centerTexte">
                            {onLanguage ? (
                                <h3>{onLanguage.demandeDeService.title}</h3>
                            ) : (
                                <Loading/>
                            )
                            } 
                        </div>
                    </div>
                    <div className={module.details.length === 2 ? ("containerModule2") : ("containerModule")}>
                        {module.details.map((icon, index) => (
                            <div onClick={() => handleSelectAction(icon.id, icon.value)} className={"module-" + (index + 1)} key={icon.id}>
                                <img className={"module module-" + icon.id + "img"} src={icon.imageUrl} alt={icon.value} />
                                <p className={icon.id}>{icon.value}</p>
                            </div>
                        ))}
                    </div>

                    <div>
                        <div  className="locations">
                            {onLanguage ? (
                                typeof selectLocalisation === 'string' ? (
                                    <input onBlur={() => handleActiveButton()} id="locationInput" className="inputLocation location" placeholder={onLanguage?.demandeDeService?.placeHolder} type="text" value={selectLocalisation}  onChange={(e) => setSelectLocalisation(e.target.value)}/>
                                ) : (
                                    <select style={{ borderLeft: `2px solid ${onData.couleur}` }} className="inputLocation iphonde location" id="locationInput">
                                        {selectLocalisation.map((item, index) => (
                                            <option key={index} value={item.trim()}>{item.trim()}</option>
                                        ))}
                                    </select>
                                )
                            ) : (
                                <input onBlur={() => handleActiveButton()} id="locationInput" className="inputLocation location" placeholder="Loading data" type="text" />
                            )}
                            <select style={{ borderLeft: `2px solid ${onData.couleur}` }} onChange={() => handleActiveButton()} className="selectLocation location" name="etage" id="etage-select" defaultValue="">
                                {onLanguage ? (
                                    <option hidden>{onLanguage.demandeDeService.etage}</option>
                                ) : (
                                    <option hidden>Loading data</option>
                                )}
                                    {selectEtage.map((item, index) => (
                                        <option key={index} value={item.trim()}>{item.trim()}</option>
                                    ))}
                            </select>
                        </div>

                        {onLanguage ? (
                            showActiveButton ? (
                                <div style={{ border: `1px solid ${onData.couleur}`, color: onData.couleur }} className="button">{onLanguage.demandeDeService.send}</div>
                            ) : (
                                <div style={{ backgroundColor: `${onData.couleur}` }} onClick={() => handleValidComment()} className="activeButton">{onLanguage.demandeDeService.send}</div>
                            )
                        ) : (
                            <Loading/>
                        )}

                        {showPopupValidationDemandeDeService && (
                            <div className="popups">
                                <div onClick={() => handleNoPopup()} className="shadow"></div>
                                <div className="radiusBorder">
                                    <ValidationDemandeDeService
                                        setShowPopupMerci={setShowPopupMerci}
                                        setShowPopupValidationDemandeDeService={setShowPopupValidationDemandeDeService}
                                        locationInput={document.getElementById("locationInput").value}
                                        selectEtage={document.getElementById("etage-select").value}
                                        selectedAction={selectedAction}
                                        mail={module.mail}
                                    />
                                </div>
                            </div>
                        )}

                        {showPopupMerci && (
                            <div className="popups">
                                <div onClick={() => handleNoPopup()} className="shadow"></div>
                                <div className="radiusBorder">
                                    <PopupMerci setShowPopupMerci={setShowPopupMerci} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            ) : (
                <Loading/>
            )}
        </>
    )
}