import React, { useState, useContext, useEffect, useRef } from 'react';
import "../styles/Header.css"
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import { useNavigate } from 'react-router-dom';
import LogoDemo from '../assets/svg/logo/GreenofficeDemo.svg';
import ContactMail from "../assets/svg/header/ContactMail.svg";
import { LanguageValue } from '../context/LanguageValue';
import { getCompleteClientInfo } from './api';
import Loading from './Loading';

export default function Header() {
    const navigate = useNavigate();
    const [showFixHeader, setShowFixHeader] = useState(false);
    const retourButtonRef = useRef(null);
    const { language } = useContext(LanguageValue);
    const [error, setError] = useState(null);    
    const [onData, setOnData] = useState("");
    const [onLanguage, setOnLanguage] = useState("");
    const { brand } = useContext(RedirectUrlValue);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { clientData, languageData } = await getCompleteClientInfo(brand, language);
                setOnData(clientData);
                setOnLanguage(languageData);
            } catch (error) {
                setError(error);
            }
        } 
        fetchData();
    }, [brand, language]);

    useEffect(() => {
        if(error) {
            console.log(error)
            navigate("/");
        }
    }, [error, navigate])

    /* chargement du logo et de la couleur de fond */
    useEffect(() => {
        if (onData.version === "demo") {
            setShowFixHeader(true);
        }
        
        if (brand === "facility") {
            let headerClass = document.getElementById("header")
            headerClass.style.backgroundImage  = "url('http://green-me.info/images/Facility/module/wiew/headerBckImg.jpg')"
            headerClass.style.color = "black"
        } else if (onData.backgroundColorHeader === true) {
            let headerClass = document.getElementById("header")
            headerClass.style.backgroundColor = onData.couleur
            headerClass.style.color = "white"
        } else {
            let headerClass = document.getElementById("header")
            headerClass.style.borderBottom = `3px solid ${onData.couleur}`;
            headerClass.style.color = onData.couleur;
        }
    }, [onData, brand]);
    
    return (
        <React.Fragment>
            {showFixHeader ? (
                <div onClick={() => navigate('/democontact')} className="fixHeader">
                    <div className='containerLogo rightPartElement'>
                        <img className='logoDemo' src={LogoDemo} alt="Logo page demo" />
                    </div>
                    {onLanguage ? (
                        <p className='rightPartElement'>{onLanguage.header.link}</p>
                    ) : (
                        <Loading/>
                    )}
                    <div className='rightPart rightPartElement'>
                        <img className='imgContact' src={ContactMail} alt="" />
                    </div>
                </div>
            ) : null}

            <header onClick={() => navigate('/mainpage')} id='header'>
                {onLanguage ? (
                    <p onClick={() => navigate(-1)} className='retourButton' id='retourButton' ref={retourButtonRef}>&lt; {onLanguage.header.retour}</p>
                ) : (
                    <Loading/>
                )}
                <img onClick={() => navigate(-1)} className='brandLogo' src={onData.logoUrl} alt="" />
            </header>
        </React.Fragment>
    );
}