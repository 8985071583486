import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function RedirectCA() {

    const navigate = useNavigate();
    
    useEffect(() => {
        navigate("/scanpage/VinciFM")
    }, [navigate])
    return(
        <React.Fragment></React.Fragment>
    )
}