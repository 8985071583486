import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import "../styles/Borne.css"
import Loading from './Loading.js';
import { getCompleteClientInfo } from './api';
import { LanguageValue } from '../context/LanguageValue';

export default function Borne(props) {
    
    const navigate = useNavigate();
    const { state } = useLocation();
    const [onData, setOnData] = useState("");
    const [error, setError] = useState(null);    
    const [onLanguage, setOnLanguage] = useState("");
    const { language } = useContext(LanguageValue);
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = useCallback (async () => {
        try {
            const { clientData, languageData } = await getCompleteClientInfo(brand, language);
            setOnData(clientData);
            setOnLanguage(languageData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    }, [brand, language]);

    if (error) {
        navigate("/");
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className='bornePage page'>
            <div className='titlePage'>
                <img src={state.svgIcon} alt="" />
                <div className='centerTexte'>
                    <h3>
                        {state.detail.value.split('|').map((part, index, array) => 
                            index === array.length - 1 ? part : <>
                                {part}
                                <span>|</span>
                            </>
                        )}  
                    </h3>
                </div>
            </div>
            <img className='borneImg' src={state.detail.image} alt="Borne de trie" />
            <div className='descriptionElement'>
                {state.detail.descriptionTrue ? (
                    <div className='trueElement'>
                        <h4 style={{color: onData.couleur}}>{state.titleTrue}</h4>
                        <ul>
                            {state.detail.descriptionTrue.map((item) => {
                                const capitalizedItem = item.trim().charAt(0).toUpperCase() + item.trim().slice(1);
                                return (
                                    <li key={item.trim()}>{capitalizedItem}</li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    <></>
                )}
                {state.detail.descriptionFalse.length > 0 ? (
                    <>
                        {/* <img className='separateurTextBorne' src={Separateur} alt="" /> */}
                        <div className='separateurTextBorne'></div>
                        <div className="falseElement">
                            <h4>{state.titleFalse}</h4>
                            <ul>    
                                {state.detail.descriptionFalse.map((item) => {
                                    const capitalizedItem = item.trim().charAt(0).toUpperCase() + item.trim().slice(1);
                                    return (
                                        <li key={item.trim()}>{capitalizedItem}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {onLanguage ? (
                <div style={{ backgroundColor: `${onData.couleur}` }} onClick={() => navigate(-1)} className="activeButton">
                {onLanguage.header.retour}
            </div>
            
            ) : (
                <Loading/>
            )}
            
        </div>
    );
}