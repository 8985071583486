import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getModuleById } from '../api';
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import "../../styles/Contact.css"

export default function Contact() {
    
    const { state } = useLocation();
    const [error, setError] = useState(null);
    const [module, setModule] = useState({});
    const { brand } = useContext(RedirectUrlValue);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const moduleData = await getModuleById(state.module);
                setModule(moduleData);
            } catch (error) {
                setError('Une erreur s\'est produite lors de la récupération des données.');
            }
        };
        fetchData();
    }, [brand, state.module])

    useEffect(() => {
        if(error) {
            console.log(error)
            navigate("/");
        }
    }, [error, navigate])

    return(
        <div className='Wiew page WiewState'>
            <div className="titlePage">
                <img src={module.imageUrl} alt="" />
                <div className="centerTexte">
                    <h3>{module.title}</h3>
                </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: module.text }}></p>        
        </div>
    )
}