import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "../styles/Admin.css";
import ArrowRight from "../assets/svg/arrow-right.svg";
import ArrowDown from "../assets/svg/arrow-down.svg"

export default function Admin() {
    const [data, setData] = useState([]);
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showDetail, setShowDetail] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        'apiKey': password
    };

    const fetchData = async () => {
        if (password.length > 40 ) {
            setError('Invalid server response');
        } else {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/api/clientinfo`,
                    { headers }
                );
                if (response.data && typeof response.data === 'object') {
                    setData(response.data);
                } else {
                    setError('Invalid server response');
                }
            } catch (error) {
                setError('Error fetching data:', error);
            }
        };
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleNavigate = (urlDestination) => {
        navigate("/scanpage/" + urlDestination);
    };

    useEffect(() => {
        if (error) {
            console.log(error)
        }
    }, [error])

    const handleShowDetail = (valuId) => {
        setShowDetail(prevArray => {
            if (!prevArray.includes(valuId)) {
                return [...prevArray, valuId]
            }
            return prevArray.filter(item => item !== valuId);
        });
    };

    return (
        <div className="page">
            <label htmlFor="password">Password : </label>
            <input 
                type="password" 
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter your password"
            />
            <button onClick={fetchData}>
                Envoyer
            </button>
            <div className="adminFlex">
                <div>
                    <p>Admin Page & Total Scan</p>
                    {data
                        .sort((a, b) => {
                            if (a.version === "payant" && b.version !== "payant") {
                                return -1; // a doit être avant b
                            }
                            if (a.version !== "payant" && b.version === "payant") {
                                return 1; // b doit être avant a
                            }
                            return 0; // Sinon, on garde l'ordre
                        })
                        .map((item) => (
                            <>
                                <div className="clientDiv">
                                    {showDetail.includes(item._id) ? (
                                        <img src={ArrowDown} onClick={() => handleShowDetail(item._id)} alt="Fléche menu dépliant" />
                                    ) : (
                                        <img src={ArrowRight} onClick={() => handleShowDetail(item._id)} alt="Fléche menu dépliant" />
                                    )}
                                    <p 
                                    className={item.version == "payant" ? ("colorText") : ("colorTextDemo")}
                                    key={item._id} 
                                    onClick={() => handleNavigate(item.client)}
                                    >
                                        {item.client} - {item.scanTotal}
                                    </p>
                                </div>
                                
                                {showDetail ? (
                                    item.détailscan && item.détailscan.length > 0 ? (
                                        showDetail.includes(item._id) ? (
                                            <ul>
                                                {item.détailscan
                                                .sort((a, b) => {
                                                    return new Date(a.mois) - new Date(b.mois)
                                                })
                                                .map((detail, index) => (
                                                    <li key={index}>
                                                        {detail.mois} : {detail.scans}
                                                    </li>
                                                ))}
                                            </ul>

                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <></>
                                )}
                            </>
                    ))}
                </div>
            </div>
        </div>
    );
}