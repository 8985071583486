import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getClientByClient } from '../api';
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import "../../styles/Wiew.css"

export default function Wiew() {
    
    const { state } = useLocation();
    const [error, setError] = useState(null);
    const [onData, setOnData] = useState("");
    const [module, setModule] = useState({});
    const [activeFilter, setActiveFilter] = useState("button1")
    const { brand } = useContext(RedirectUrlValue);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await getClientByClient(brand);
                setOnData(clientData);
                const moduleData = state.borne;
                setModule(moduleData);
            } catch (error) {
                setError('Une erreur s\'est produite lors de la récupération des données.');
            }
        };
        fetchData();
    }, [brand, state.borne]);

    useEffect(() => {
        if (error) {
            navigate("/");
        }
    }, [error, navigate]);

    useEffect(() => {
        console.log(activeFilter)
    }, [activeFilter])

    setTimeout(() => {
        const graphCircle = document.querySelector('.graph-circle');
        if (graphCircle) {
            if (module.bornevide/module.bornetotal*100 < 50 ) {
                graphCircle.classList.add('circle-animation-false');
            } else {
                graphCircle.classList.add('circle-animation-true');
            }
        }
    }, 100);

    let dateObj = new Date();
    let day = dateObj.getDate();
    let month = dateObj.getMonth();
    
    console.log(state)

    return(
        <>
            {module ? (
                <div className='Wiew page WiewState'>
                    <div className='titlePage'>
                        <img src={state.imageUrl} alt="" />
                        <div className='centerTexte'>
                            <p>ETAT DES BORNES : {state.borne.text}</p>
                        </div>
                    </div>
                    <div className="topTotalData">
                        {state.borne.image ? (
                            <img src={state.borne.image} alt="Borne client" />
                        ) : (
                            <img src={state.imageUrl} alt="Borne client" />
                        )}
                        <div className="TotalData">
                            <div className="animation-graph-container mb-3">
                                <svg viewBox="-2 -2 40 25">
                                    <path className="circle-bg" strokeDasharray={`50, 100`}
                                        d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path className="graph-circle" strokeDasharray={`${Math.trunc((module.bornevide/module.bornetotal)*100)/2}, 100`}
                                        d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    {Math.trunc((module.bornevide/module.bornetotal)*100) === 100 ? (
                                        <text x="7" y="17.5" className="graph-text">{Math.trunc((module.bornevide/module.bornetotal)*100)}%</text>

                                    ) : (
                                        <text x="9" y="17.5" className="graph-text">{Math.trunc((module.bornevide/module.bornetotal)*100)}%</text>
                                    )}
                                </svg>
                            </div>
                            <div className="filter">
                                <p>{module.bornevide}/{module.bornetotal} BORNES VIDÉES</p>
                                <p>{day-1}/{month} 6H47 - {day-1}/{month} 16H50</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="buttonFilter">
                        <button id="button1" className="activeButton" onClick={() => setActiveFilter("button1")} style={{background: activeFilter === "button1" ? onData.couleur : "grey"}} >Toutes</button>
                        <button id="button2" className="activeButton" onClick={() => setActiveFilter("button2")} style={{background: activeFilter === "button2" ? onData.couleur : "grey"}} >Vidées</button>
                        <button id="button3" className="activeButton" onClick={() => setActiveFilter("button3")} style={{background: activeFilter === "button3" ? onData.couleur : "grey"}} >Non vidées</button>
                    </div>
                    <div>
                        {activeFilter === "button1" && (
                            <>
                                {Array.from({ length: module.bornevide }).map((_, index) => (
                                    <div key={index} className="card cardTrue">
                                        <p className="textcenter">Borne {index + 1} : 1er</p>
                                        <p>16 : 10</p>
                                    </div>
                                ))}
                                {Array.from({ length: (module.bornetotal - module.bornevide) }).map((_, index) => (
                                    <div key={index + module.bornevide} className="card cardFalse" >
                                        <p className="textcenter">Borne {index + parseInt(module.bornevide) + 1} : 1er</p>
                                        <p>Hier</p>
                                    </div>
                                ))}
                            </>
                        )}
                        {activeFilter === "button2" && (
                            Array.from({ length: module.bornevide }).map((_, index) => (
                                <div key={index} className="card cardTrue">
                                    <p className="textcenter">Borne {index + 1} : 1er</p>
                                    <p>16 : 10</p>
                                </div>
                            ))
                        )}
                        {activeFilter !== "button1" && activeFilter !== "button2" && (
                            Array.from({ length: (module.bornetotal - module.bornevide) }).map((_, index) => (
                                <div key={index + module.bornevide} className="card cardFalse" >
                                    <p className="textcenter">Borne {index + parseInt(module.bornevide) + 1} : 1er</p>
                                    <p>Hier</p>
                                </div>
                            ))
                        )}
                    </div>

                </div>
            ) : (
                <p>Loading Data ....</p>
            )}
        </>
    )
}