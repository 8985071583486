import React from 'react';
import '../styles/Loading.css';

const Loading = () => {
    const loadingText = 'Loading...';

    return (
        <div className="loading">
        {loadingText.split('').map((char, i) => (
            <div style={{ animationDelay: `${0.1 * (i + 1)}s` }} key={i}>
            {char}
            </div>
        ))}
        </div>
    );
};

export default Loading;
