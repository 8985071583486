import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getModuleById } from '../api';
import "../../styles/QueDevientMonDechet.css"
import Loading from '../Loading';

export default function QueDevientMonDechet() {
    
    const { state } = useLocation();
    const [error, setError] = useState(null);
    const [module, setModule] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const moduleData = await getModuleById(state.module);
                setModule(moduleData);
            } catch (error) {
                setError('Une erreur s\'est produite lors de la récupération des données.');
            }
        };
        fetchData();
    }, [state.module]);

    useEffect(() => {
        if (error) {
            navigate("/");
        }
    }, [error, navigate]);

    if (!module) {
        return <Loading/>
    }

    return(
        <div className="pdfPage">
            <div className="titlePage">
                <img src={module.imageUrl} alt="" />
                <div className="centerTexte">
                    <h3>{module.title}</h3>
                </div>
            </div>
            <img src={module.url} alt="PDF du client" />
        </div>
    )
}