import React, { useEffect, useState } from "react";
import FrenchFlag from "../../assets/svg/header/Drapeau01.svg";
import DeutchFlag from "../../assets/svg/header/Drapeau03.svg";
import EnFlag from "../../assets/svg/header/Drapeau02.svg";

// Ajoutez les props ici
export default function LanguageSelector({ setShowPopupSelectLangue, onData, setOnData, setLangueIsSelect }) {

    const [langueChoix, setLangueChoix] = useState([])

    useEffect(() => {
        if (onData && onData.langue !== undefined) {
            setLangueChoix(onData.langue.split(' '))
        }
    }, [onData])

    function handleSelectLangue(langue) {
        const unpdatedOndata = { ...onData, langue: langue }
        setOnData(unpdatedOndata)
        localStorage.setItem('selectedLanguage', langue);
        setShowPopupSelectLangue(false)
        setLangueIsSelect(true)
    };

    return(
        <div className="popup popupSelectLangue">
            {onData ? (
                <>
                    <div className="flagSelector">
                        <>
                            {langueChoix.includes("Français") ? (
                                <img onClick={() => handleSelectLangue("Français")} src={FrenchFlag} alt="Drapeau Français" />
                            ) : (
                                <></>
                            )}
                        </>
                        <>
                            {langueChoix.includes("deutsch") ? (
                                <img onClick={() => handleSelectLangue("deutsch")} src={DeutchFlag} alt="Drapeau allemand" />
                                ) : (
                                <></>
                            )}
                        </>
                        <>
                            {langueChoix.includes("anglais") ? (
                                <img onClick={() => handleSelectLangue("anglais")} src={EnFlag} alt="Drapeau anglais" />
                                ) : (
                                <></>
                            )}
                        </>
                    </div>
                </>
            ) : (
                <p>Loading data...</p>
            )}
        </div>
    )
}