import React, {createContext, useState, useEffect} from "react";

export const RedirectUrlValue = createContext();

const RedirectUrlValueProvider = (props) => {

    const [brand, setBrand] = useState(localStorage.getItem('brand'));

    useEffect(() => {
        localStorage.setItem('brand', brand);
    }, [brand])

    const toggleredirectUrl = ( brand ) => {
        setBrand(brand);
    }
    
    return (
        <RedirectUrlValue.Provider value={{toggleredirectUrl, brand}}>
            {props.children}
        </RedirectUrlValue.Provider>
    )

}

export default RedirectUrlValueProvider;