import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function RedirectCA() {

    const navigate = useNavigate();
    
    useEffect(() => {
        navigate("/scanpage/cacentreest/france/champagneaumontdor/pierredetruchisdelays/5/?auth=b6ed03b2fb40")
    }, [navigate])

    return(
        <React.Fragment></React.Fragment>
    )
}

