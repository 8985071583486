import React, { useState, useEffect, useContext, useCallback } from 'react';
import "../styles/MainPage.css"
import { useNavigate } from 'react-router-dom';
import LogoAnnimation from "./LogoAnnimation";
import { getCompleteClientInfo, getOnLanguage } from './api';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import DesactiveModule from './popup/DesactiveModule';
import SelectLangue from './popup/SelectLangue.js';
import { LanguageValue } from '../context/LanguageValue';
import LogoDemo from '../assets/svg/logo/GreenofficeDemo.svg';
import ContactMail from "../assets/svg/header/ContactMail.svg";
import Loading from './Loading';

export default function Mainpage() {

    const [showPopupDesacModule, setShowPopupDesacModule] = useState(false);
    const [showPopupSelectLangue, setShowPopupSelectLangue] = useState(false);
    const navigate = useNavigate();
    const [onData, setOnData] = useState("");
    const [onLanguage, setOnLanguage] = useState("");
    const [module, setModule] = useState([]);
    const [error, setError] = useState(null);
    const { brand } = useContext(RedirectUrlValue);
    const { toggleLanguage, language } = useContext(LanguageValue);
    const [showFixHeader, setShowFixHeader] = useState(false);
    const [langueIsSelect, setLangueIsSelect] = useState(false);
    const [multiLingue, setMultiLingue] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { clientData, languageData, moduleData } = await getCompleteClientInfo(brand, language);
                setOnData(clientData);
                setOnLanguage(languageData);
                setModule(moduleData);
            } catch (error) {
                setError('Une erreur s\'est produite lors de la récupération des données.');
            }
        }
        fetchData();
    }, [brand, language]);

    const fetchDataLangue = useCallback(async () => {
        try {
            const langueData = await getOnLanguage(onData.langue);
            setOnLanguage(langueData)
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    }, [onData.langue]);

    useEffect(() => {
        if(error) {
        console.log(error);
        }
    }, [error]);
    
    useEffect(() => {
        const selectedLanguageLocal = localStorage.getItem('selectedLanguage');
        if (onData && onData.langue !== undefined) {
            const languageList = onData.langue.split(' ');
            
            if(languageList.length > 1) {
                if (selectedLanguageLocal) {
                    const unpdatedOndata = { ...onData, langue: selectedLanguageLocal }
                    setOnData (unpdatedOndata)
                    setMultiLingue(true)
                } else {
                    setShowPopupSelectLangue(true);
                    setMultiLingue(true)
                }
            } else {
                fetchDataLangue()
                setLangueIsSelect(true)
            }
        }
    }, [onData, fetchDataLangue])    

    useEffect(() => {
        if (langueIsSelect === true) {
            let modulesConcernes = module;
            let sliderWidth;

            if (multiLingue === true) {
                modulesConcernes = module.filter(m => m.langue === 'Français');
            }
    
            sliderWidth = (modulesConcernes.length) * 115 + 40;
            const sliderModule = document.getElementById("sliderModuleId");
    
            if (sliderModule) {
                if (sliderWidth < window.innerWidth) {
                    sliderModule.classList.add("noSliderModule");
                    sliderModule.classList.remove("sliderModule");
                } else {
                    sliderModule.classList.add("sliderModule");
                    sliderModule.classList.remove("noSliderModule");
                }
            }
        }
    }, [module, langueIsSelect, multiLingue]);
    
    const handleClick = (paramRedirect, param, active) => {
        toggleLanguage(onData.langue)
        if (active === false) {
            console.log("0_o")
            setShowPopupDesacModule(true);
        } else if (paramRedirect === "link") {
            const foundModule = module.find(module => module._id === param.module);
            window.location.href = foundModule.url;
        } else {
            navigate('/' + paramRedirect, { state: param});
        }
    };

    if (error) {
        navigate("/");
    }

    function handleNoPopup() {
        setShowPopupDesacModule(false);
    }

    setTimeout(() => {
        if ( showPopupDesacModule === true ) {
            setShowPopupDesacModule ( false )
        }
    }, 5000)

    useEffect(() => {
        if (onData.version === "demo") {
            setShowFixHeader(true);
        }
    }, [onData]);

    const priority = {
        'TypeDeDechet': 1,
        'queDevientMonDechet': 2,
        'DemandeDeService': 3
    };

    return (
        <div className="mainPage">

            {showFixHeader ? (
                <div onClick={() => navigate('/democontact')} className="fixHeader">
                    <div className='containerLogo rightPartElement'>
                        <img className='logoDemo' src={LogoDemo} alt="Logo page demo" />
                    </div>
                    {onLanguage ? (
                        <p className='rightPartElement'>{onLanguage.header.link}</p>
                    ) : (
                        <Loading/>
                    )}
                    <div className='rightPart rightPartElement'>
                        <img className='imgContact' src={ContactMail} alt="" />
                    </div>
                </div>
            ) : null}
            
            {showPopupDesacModule ? (
                <div className="popups">
                    <div onClick={() => handleNoPopup()} className="shadow"></div>
                    <div className="radiusBorder">
                        <DesactiveModule showPopupDesacModule={showPopupDesacModule} />
                    </div>
                </div>
            ): null }
            
            {showPopupSelectLangue ? (
                <div className="popups">
                    <div className="shadow"></div>
                    <div className="radiusBorder">
                        <SelectLangue
                            setShowPopupSelectLangue={setShowPopupSelectLangue}
                            onData={onData}
                            setOnData={setOnData}
                            setLangueIsSelect={setLangueIsSelect}
                        />
                    </div>
                </div>
            ): null }

            { onData.backgroundimg === false ? (
                <div className='noBckImg'>
                    <LogoAnnimation />
                </div>   
            ) : (
                <div className='bckImg' style={onData.backgroundimg ? { backgroundImage: `url(${onData.bckImgUrl})` } : null}>
                    {onData && <LogoAnnimation opacity={onData.gmLogo}/>}
                </div>
            )}

            {langueIsSelect ? (
                <div>
                    {onLanguage ? (
                        <h2>{onLanguage.mainPage.workplace}</h2>
                        ) : (
                            <Loading/>
                        )
                    }
                    <div id='sliderModuleId'>
                        {module
                            .filter(item => !multiLingue || item.langue === onData.langue)
                            .sort((a,b) => {
                                return (priority[a.type] || 99) - (priority[b.type] || 99);
                            })
                            .map((item) => (
                                <React.Fragment key={item._id}>
                                    <div onClick={() => handleClick(item.type, { module: item._id }, item.active)} className={item.visible && item.active ? 'module' : (item.visible ? 'desac module' : ( item.active ? 'module masquer' : 'module masquer desac'))}>
                                        <img src={item.imageUrl} alt="" />
                                        <p className="textModule">
                                            {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                        </p>
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </div>
    );
}