import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import axios from 'axios';
import { ReactComponent as Gmclassic } from "../assets/svg/logo/GmClassic.svg";
import "../styles/Home.css";

export default function Scanpage() {

    const { toggleredirectUrl } = useContext(RedirectUrlValue);
    const [isScan, setIsScan] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const headers = useMemo(() => ({
        'Content-Type': 'application/json',
        'apiKey': process.env.REACT_APP_API_SECRET_HASH
    }), []);

    const client = window.location.href.split('/')[4].toLowerCase();

    const updateData = useCallback(async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo/${client}/increment`, {}, { headers });
        } catch (error) {
            throw new Error("Erreur lors de la mise à jour des données");
        }
    }, [client, headers]);

    const handleScan = useCallback((scanData) => {
        const qrResult = scanData.toLowerCase();
        if (qrResult.includes("greenme.info") || qrResult.includes("goserveurtest.fr") || qrResult.includes("localhost")) {
            const urlSplit = qrResult.split("/");
            const urlArray = urlSplit.slice(urlSplit.length - 6).slice(0, 5);
            toggleredirectUrl(urlArray[0]);
            localStorage.setItem('selectedLanguage', "");
            setIsScan(true);
        }
    }, [toggleredirectUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await updateData();
                handleScan(window.location.href);
            } catch (error) {
                console.error(error.message);
                try {
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/errors`, {
                        error: error.message,
                        client,
                        time: new Date().toISOString()
                    }, { headers });
                } catch (loggingError) {
                    console.error("Erreur lors de l'enregistrement de l'erreur", loggingError);
                }
                handleScan(window.location.href);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [client, headers, handleScan, updateData]);

    useEffect(() => {
        if (isScan) {
            window.location.href = "/mainpage";
        }
    }, [isScan]);

    return (
        <div className="load">
            {isLoading && <Gmclassic className="Gmclassic" fill="#1B9A68" aria-label="Flèche retour" />}
        </div>
    );
}